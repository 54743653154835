import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Routes, Route, Redirect, Navigate } from "react-router-dom";
import "./index.css";
import i18n from "./langs/i18n";
import { HelmetProvider } from "react-helmet-async";
import SuspenseRouter from "./SuspenseRouter";
import { GlobalContextProvider } from "./GlobalContext";
import Launcher from "./components/launcher/Launcher";
const Home = lazy(() => import("./components/home/Home"));
const PondMother = lazy(
  () => import("./components/technology/pondmother/PondMother"),
);
const ShrimpTalk = lazy(
  () => import("./components/technology/shrimptalk/ShrimpTalk"),
);
const PondGaurdNeo = lazy(
  () => import("./components/technology/pondgaurdneo/PondGaurdNeo"),
);
const PondLogs = lazy(
  () => import("./components/technology/pondlogs/PondLogs"),
);
const TermsConditions = lazy(
  () => import("./components/footer/TermsConditions"),
);
const PrivacyPolicy = lazy(() => import("./components/footer/PrivacyPolicy"));
const InvestorRelations = lazy(
  () => import("./components/footer/InvestorRelations"),
);
const AnnualReturns = lazy(
  () => import("./components/footer/AnnualReturnsPage"),
);
const Company = lazy(() => import("./components/aboutus/Company"));
const Careers = lazy(() => import("./components/careers/Careers"));

// const Description = lazy(() => import("./Description"))
// const Reviews = lazy(() => import("./Reviews"))

const routeConfig = [
  {
    name: "Laucher",
    path: "/launcher",
    component: (
      <Launcher></Launcher>
    ),
  },
  {
    name: "Home",
    path: "/",
    component: <Home />
  },
  { name: "PondMother", path: "/pondmother", component: <PondMother /> },
  { name: "ShrimpTalk", path: "/shrimpTalk", component: <ShrimpTalk /> },
  { name: "PondGaurdNeo", path: "/pondgaurdneo", component: <PondGaurdNeo /> },
  { name: "PondLogs", path: "/pondlogs", component: <PondLogs /> },
  {
    name: "TermsConditions",
    path: "/terms-and-conditions",
    component: <TermsConditions />,
  },
  {
    name: "PrivacyPolicy",
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    name: "InvestorRelations",
    path: "/investor-relations",
    component: <InvestorRelations />,
  },
  {
    name: "AnnualReturns",
    path: "/annual-returns",
    component: <AnnualReturns />,
  },
  { name: "Company", path: "/company", component: <Company /> },
  { name: "Company", path: "/about", component: <Company /> },
  { name: "Careers", path: "/careers", component: <Careers /> },
  { name: "No Match", path: "*", component: <Navigate replace to="/" />}
];

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <SuspenseRouter>
          <Suspense fallback={<div className="loader"></div>}>
            <Routes>
              {routeConfig.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <GlobalContextProvider>
                      {route.component}
                    </GlobalContextProvider>
                  }
                  action={route.action}
                />
              ))}
            </Routes>
          </Suspense>
        </SuspenseRouter>
      </HelmetProvider>
    </I18nextProvider>
  </React.StrictMode>,
);
