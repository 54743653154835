import { createSearchParams, Link, useNavigate } from "react-router-dom";
import "./Launcher.css";
import confetti from "canvas-confetti";
import { useState } from "react";
import { LogoDark } from "../../assets/home";
export default function Launcher({ children }) {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const navigate = useNavigate();
  const delayRedirect = (event) => {
    setIsRedirecting(true);
    event.preventDefault();
    setTimeout(() => {
      navigate({
        pathname: "/",
        search: createSearchParams({
          celebrate: "launched",
        }).toString(),
      });
    }, 500);
    // setIsRedirecting(false)
  };
  return (
    <div className="curtain">
      {/* <div className="curtain__content">{children}</div> */}
      <div className={`curtain__wrapper ${!isRedirecting ? `checked` : ""}`}>
        <div className="curtain__panel curtain__panel--left"></div>
        <div className="curtain__panel curtain__panel--right"></div>
        {isRedirecting ? (
          <div
            className={`circle-loader ${isRedirecting ? "circle-grow-1" : ""} absolute left-1/2 top-1/2 z-30 flex h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full shadow-lg transition-transform`}
          ></div>
        ) : (
          <>
            {/* <div className="launch-button-backdrop absolute left-1/2 top-1/2 z-30 flex flex-col h-80 w-80 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center overflow-hidden rounded-full shadow-lg">
              <div className="w-full h-3/5 bg-[#d1d2d2] "></div>
              <div className="w-full h-2/5 bg-[#2aa3bf] "></div>
            </div> */}
            <Link
              to={{
                pathname: "/",
                search: createSearchParams({
                  celebrate: "launched",
                }).toString(),
              }}
              onClick={delayRedirect}
              className={`launch-button absolute left-1/2 top-1/2 z-30 flex h-80 w-80 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center overflow-hidden rounded-full shadow-lg`}
            >
              <ul className="bands flex h-full w-full flex-col">
                <li className="band-1">
                  <img
                    className="mb-1 h-24 w-24"
                    src={LogoDark}
                    alt="Eruvaka logo"
                  />
                  <p className="text-lg tracking-wider text-[#4b7c79]">
                    Eruvaka
                  </p>
                </li>
                <li className="band-2 text-center">
                  <p className="text-base text-[#fff]"> Website Launch</p>
                </li>
                <li className="band-3 m-auto flex w-full flex-col items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="#24737d"
                    className="w-8 -translate-y-[0.25rem] scale-[0.8] transform"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </li>
              </ul>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
