import {Mixpanel} from "./config/mixpanel.js";
import { createContext, useRef } from 'react';

const GlobalContext = createContext();

function GlobalContextProvider({children}) {
    const mixpanelRef = useRef(Mixpanel);
    return (
        <GlobalContext.Provider value={mixpanelRef}>
            {children}
        </GlobalContext.Provider>
    )
}

export {GlobalContextProvider,GlobalContext}