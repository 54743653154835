export const frame65 = `${process.env.REACT_APP_MEDIA_URL}/home/homepage-clean-energy.webp`;
export const two = `${process.env.REACT_APP_MEDIA_URL}/home/homepage-efficient-feeding.webp`;
export const three = `${process.env.REACT_APP_MEDIA_URL}/home/homepage-eco-world.webp`;
export const four = `${process.env.REACT_APP_MEDIA_URL}/home/homepage-earth.webp`;
export const globe = `${process.env.REACT_APP_MEDIA_URL}/home/homepage-accross-globe.webp`;
export const shrimp = `${process.env.REACT_APP_MEDIA_URL}/home/shrimp-survival.webp`;
export const biomass = `${process.env.REACT_APP_MEDIA_URL}/home/biomass-harvested.webp`;
export const feed = `${process.env.REACT_APP_MEDIA_URL}/home/feed-conversion-ratio.webp`;
export const production = `${process.env.REACT_APP_MEDIA_URL}/home/production-cost.webp`;
export const frame = `${process.env.REACT_APP_MEDIA_URL}/home/weekly-shrimp-growth.webp`;
export const pondmother = `${process.env.REACT_APP_MEDIA_URL}/home/pond-mother.webp`;
export const shrimptalks = `${process.env.REACT_APP_MEDIA_URL}/home/shrimp-talk.webp`;
export const pondgaurd = `${process.env.REACT_APP_MEDIA_URL}/home/pond-guard.webp`;
export const pondlogs = `${process.env.REACT_APP_MEDIA_URL}/home/pondlogs.webp`;
export const image2 = `${process.env.REACT_APP_MEDIA_URL}/home/image-app-store.webp`;
export const image3 = `${process.env.REACT_APP_MEDIA_URL}/home/image-play-store.webp`;
export const slider = `${process.env.REACT_APP_MEDIA_URL}/home/ponds-ecuador.webp`;
export const people = `${process.env.REACT_APP_MEDIA_URL}/home/people-meeting-seminar-office-concept.webp`;
export const img = `${process.env.REACT_APP_MEDIA_URL}/home/Img.webp`;
export const heading5 = `${process.env.REACT_APP_MEDIA_URL}/home/heading 5.webp`;
export const heading6 = `${process.env.REACT_APP_MEDIA_URL}/home/heading 6.webp`;
export const card_01 = `${process.env.REACT_APP_MEDIA_URL}/home/safety-week.webp`;
export const card_02 = `${process.env.REACT_APP_MEDIA_URL}/home/IMC-digital-awards.webp`;
export const card_03 = `${process.env.REACT_APP_MEDIA_URL}/home/corporate-social-responsibility.webp`;
export const fish = `${process.env.REACT_APP_MEDIA_URL}/home/shrimp.webp`;
export const Group2 = `${process.env.REACT_APP_MEDIA_URL}/home/Group 2.webp`;
export const LogoDark = `${process.env.REACT_APP_MEDIA_URL}/home/logo-dark.webp`
export const BannerThumbnail = `${process.env.REACT_APP_MEDIA_URL}/home/bannervideo_thumbnail.webp`
export const HomeAssets = {
  globe,
  fish,
  shrimp,
  biomass,
  feed,
  production,
  frame,
  pondmother,
  shrimptalks,
  pondgaurd,
  pondlogs,
  image2,
  image3,
  slider,
  people,
  img,
  heading5,
  heading6,
  card_01,
  card_02,
  card_03,
  two,
  three,
  four,
  frame65,
  Group2,
  LogoDark,
  BannerThumbnail
};

export default {
  HomeAssets
}